.badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    width: 26%;

    @media (min-width: 450px) {
      width: 21%;
    }

    @media (min-width: 600px) {
      width: 17%;
    }
  }

  li {
    &.animate {
      animation: animation 2000ms linear 1 both;
    }
  }
}

.dash-badges {
  .dash-header-ajax {
    margin-top: 20px;
  }

  #dash-badges-content {
    padding-top: 0px;

    .badges {
      li {
        margin-bottom: 40px;
      }
    }
  }
}

/* Generated with Bounce.js. Edit at https://goo.gl/ikZOHU */
@keyframes animation {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  1.59% {
    transform: matrix3d(0.814, 0, 0, 0, 0, 0.814, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  3.14% {
    transform: matrix3d(0.685, 0, 0, 0, 0, 0.685, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.45% {
    transform: matrix3d(0.609, 0, 0, 0, 0, 0.609, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.55% {
    transform: matrix3d(0.605, 0, 0, 0, 0, 0.605, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.69% {
    transform: matrix3d(
      0.598,
      0.002,
      0,
      0,
      -0.002,
      0.598,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  6.23% {
    transform: matrix3d(
      0.544,
      0.022,
      0,
      0,
      -0.022,
      0.544,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  8.19% {
    transform: matrix3d(
      0.508,
      0.034,
      0,
      0,
      -0.034,
      0.508,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  8.99% {
    transform: matrix3d(
      0.499,
      0.037,
      0,
      0,
      -0.037,
      0.499,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  9.09% {
    transform: matrix3d(
      0.499,
      0.037,
      0,
      0,
      -0.037,
      0.499,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  9.33% {
    transform: matrix3d(
      0.513,
      0.039,
      0,
      0,
      -0.039,
      0.513,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  11.83% {
    transform: matrix3d(
      0.669,
      0.055,
      0,
      0,
      -0.055,
      0.669,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  12.42% {
    transform: matrix3d(
      0.706,
      0.058,
      0,
      0,
      -0.058,
      0.706,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  13% {
    transform: matrix3d(
      0.74,
      0.06,
      0,
      0,
      -0.06,
      0.74,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  16.92% {
    transform: matrix3d(
      0.927,
      0.064,
      0,
      0,
      -0.064,
      0.927,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  20.83% {
    transform: matrix3d(
      1.021,
      0.054,
      0,
      0,
      -0.054,
      1.021,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  21.52% {
    transform: matrix3d(
      1.03,
      0.051,
      0,
      0,
      -0.051,
      1.03,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  24.74% {
    transform: matrix3d(
      1.045,
      0.039,
      0,
      0,
      -0.039,
      1.045,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  30.62% {
    transform: matrix3d(
      1.027,
      0.021,
      0,
      0,
      -0.021,
      1.027,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  32.76% {
    transform: matrix3d(
      1.018,
      0.017,
      0,
      0,
      -0.017,
      1.018,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  34.84% {
    transform: matrix3d(
      1.01,
      0.013,
      0,
      0,
      -0.013,
      1.01,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  44.94% {
    transform: matrix3d(
      0.997,
      0.004,
      0,
      0,
      -0.004,
      0.997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  45.45% {
    transform: matrix3d(
      0.997,
      0.004,
      0,
      0,
      -0.004,
      0.997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  53.69% {
    transform: matrix3d(
      0.999,
      0.001,
      0,
      0,
      -0.001,
      0.999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  65.15% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.44% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  95.45% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
