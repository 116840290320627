/*!
 * Jasny Bootstrap v3.1.0 (http://jasny.github.com/bootstrap)
 * Copyright 2011-2014 Arnold Daniels.
 * Licensed under Apache-2.0 (https://github.com/jasny/bootstrap/blob/master/LICENSE)
 */

.nav-tabs-bottom {
  border-bottom: 0;
  border-top: 1px solid #dddddd;
}
.nav-tabs-bottom > li {
  margin-bottom: 0;
  margin-top: -1px;
}
.nav-tabs-bottom > li > a {
  border-radius: 0 0 4px 4px;
}
.nav-tabs-bottom > li > a:hover,
.nav-tabs-bottom > li > a:focus,
.nav-tabs-bottom > li.active > a,
.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-bottom > li.active > a:focus {
  border: 1px solid #dddddd;
  border-top-color: transparent;
}
.nav-tabs-left {
  border-bottom: 0;
  border-right: 1px solid #dddddd;
}
.nav-tabs-left > li {
  margin-bottom: 0;
  margin-right: -1px;
  float: none;
}
.nav-tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  margin-bottom: 2px;
}
.nav-tabs-left > li > a:hover,
.nav-tabs-left > li > a:focus,
.nav-tabs-left > li.active > a,
.nav-tabs-left > li.active > a:hover,
.nav-tabs-left > li.active > a:focus {
  border: 1px solid #dddddd;
  border-right-color: transparent;
}
.row > .nav-tabs-left {
  padding-right: 0;
  padding-left: 15px;
  margin-right: -1px;
  position: relative;
  z-index: 1;
}
.row > .nav-tabs-left + .tab-content {
  border-left: 1px solid #dddddd;
}
.nav-tabs-right {
  border-bottom: 0;
  border-left: 1px solid #dddddd;
}
.nav-tabs-right > li {
  margin-bottom: 0;
  margin-left: -1px;
  float: none;
}
.nav-tabs-right > li > a {
  border-radius: 0 4px 4px 0;
  margin-left: 0;
  margin-bottom: 2px;
}
.nav-tabs-right > li > a:hover,
.nav-tabs-right > li > a:focus,
.nav-tabs-right > li.active > a,
.nav-tabs-right > li.active > a:hover,
.nav-tabs-right > li.active > a:focus {
  border: 1px solid #dddddd;
  border-left-color: transparent;
}
.row > .nav-tabs-right {
  padding-left: 0;
  padding-right: 15px;
}
.table.rowlink td:not(.rowlink-skip),
.table .rowlink td:not(.rowlink-skip) {
  cursor: pointer;
}
.table.rowlink td:not(.rowlink-skip) a,
.table .rowlink td:not(.rowlink-skip) a {
  color: inherit;
  font: inherit;
  text-decoration: inherit;
}
.table-hover.rowlink tr:hover td,
.table-hover .rowlink tr:hover td {
  background-color: #cfcfcf;
}
