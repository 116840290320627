body,
html {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
//top level
#app {
  background-color: $ysa_bg;

  &.admin-view {
    background-color: $ysa_teal;
  }
}
body.modal-open {
  overflow: hidden;
}

//slightly round images
img {
  border-radius: 2px;
}

.container {
  max-width: 768px;

  &.expanded-width {
    max-width: 970px;
  }
}

#content {
  background-color: $ysa_white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  //padding-top: $grid-gutter-width/2;
  //padding-bottom: $grid-gutter-width/2;
}

.inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

section#content {
  min-height: 500px;
}

.tease {
  a {
    color: $ysa_white;
  }
}

img.color-bar {
  height: 50px;
  width: 100%;
  //vertical-align: inherit;
  border-radius: 0px;
}

// border-right on some columns
.line-right {
  @media (min-width: $screen-sm-min) {
    border-right: 1px solid $ysa_grey;
    margin: 10px 0;

    h3,
    h4 {
      margin-top: 0;
    }

    p,
    ul {
      margin-bottom: 0;
    }
  }
}

.line-left {
  @media (min-width: $screen-sm-min) {
    border-left: 1px solid $ysa_grey;
    margin: 10px 0;

    h3,
    h4 {
      margin-top: 0;
    }

    p,
    ul {
      margin-bottom: 0;
    }
  }
}

.alert {
  text-align: left;
}

.alert-error {
  @extend .alert-danger;
}

.form-control {
  border: none;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.btn {
  //  border: none !important;
  border-radius: 5px;
  //  font-size: 20px;
}

.btn-teal {
  background-color: $ysa-teal;
  color: $ysa-white;
  font-family: $headings-font-family;

  &:hover,
  &:focus,
  &.focus {
    background-color: darken($ysa-teal, 10%);
    color: $ysa-white;
  }
}

.full-width {
  width: 100% !important;
}

hr.in-card {
  &.full-width {
    @include fullWidthInCard();
  }
  margin-top: 8px;
  border-top-width: 2px;
  border-top-color: #ececec;
}

label,
.datepicker-switch {
  @include museo-bold;
}

.strand-heading {
  display: flex;
  align-items: center;
}

.featherlight {
  .featherlight-inner {
    //display: block !important;
  }

  .featherlight-close-icon {
    font-size: 0;
    margin: 5px;

    &:before {
      @include fa-icon;
      content: $fa-var-times;
      font-size: 24px;
    }
  }
}

#assessment-disciplines {
  label {
    width: 100px;
    text-align: center;
    padding-left: 0;
  }
}

.btn {
  @include museo-reg();
  line-height: 18px;
  padding: 10px;
}

p.info-text {
  @include museo-reg();
  color: $grey;
}

.grey-text {
  color: $grey;
}

p.normal-text {
  @include museo-reg();

  strong {
    @include museo-bold();
  }
}
.progress-bar {
  box-shadow: none;
  border-radius: 6px;
}

// bootstrap overwrite
.alert {
  //margin-bottom: 0;
  border-radius: 5px;
}

.default-checkbox-icon {
  margin-right: 5px;
  margin-top: -3px;
}

input[type="file"] {
  @include museo-reg();
}

a.link {
  &.underline {
    text-decoration: underline;
  }

  &.teal {
    color: $ysa_teal;
  }
}

.icon-info {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  flex-shrink: 0;
  flex-grow: 0;

  fill: $ysa_teal;
}

.museo-reg {
  @include museo-reg();
}

.section-small {
  margin-bottom: 30px;
}

.section-big {
  margin-bottom: 60px;
}

a.detach-superuser,
a.detach-participant,
a.detach-partneruser {
  cursor: pointer;
  color: $ysa_red !important;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.select2-container {
  z-index: 2000; // fuck you featherlight
}

.cursor-auto {
  cursor: auto;
}

.columns-2 {
  columns: 2;
}

.d-inline-block {
  display: inline-block;
}
html.privacy-policy-open {
  overflow: hidden !important;
  #app {
    filter: blur(5px);
  }
}
