.messages,
.dash-messages {
  .unread-icon {
    margin-left: 5px;
  }

  .message-meta,
  .media {
    border-bottom: 1px solid $lightgrey;
  }

  .media {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }

  .thread-unread {
    background-color: $grey2;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 0;
  }

  .message-meta {
    font-size: 14px;
    @include museo-reg();
  }
}
