//  DEFAULT
.ysa-panel {
  @include museo-reg();
  .inactive-account {
    color: $ysa-red;
  }

  a.name {
    color: $ysa_black;
  }
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 10px 10px 46px -19px rgba(0, 0, 0, 0.2);
  }
  margin-bottom: 20px;
  background-color: $grey2light;
  border: 1px solid $grey2;
  border-radius: 10px;
  .panel-heading {
    @include museo-reg();
    cursor: pointer;
    border: none;
    background-color: $grey2;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    // flex used to put
    // titles on the left
    // meta on the right
    display: flex;
    justify-content: space-between;

    // titles are on the left side of the heading
    // include sup-title (for activity moderations only)
    // and title (normal field for all panels)
    .panel-heading-titles {
    }

    // meta is on the right side
    // includes left and right sections
    .panel-heading-meta {
      display: flex;
      align-self: flex-end; // make sure the meta is aligned to the bottom when the sup-title is present
      align-items: baseline; // align icons and text

      .panel-heading-meta-right {
        display: flex;
        padding-left: 10px;
        border-left: solid 2px $grey;
      }

      .panel-heading-meta-left {
        //display: flex;  disabled because it broke time-fractions, hope it wasn't necessary
        padding-right: 10px;
      }
    }
  }
  .panel-body {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;

    // right section includes strand and disciplines
    .panel-body-right {
      display: flex; //align vertically
      justify-content: flex-end; // align to the right

      .strand {
        align-self: center;
        padding-left: 7px;
        padding-right: 7px;
        border-left: solid 2px $grey2;
      }

      .disciplines {
        padding-left: 5px;
        border-left: solid 2px $grey2;
      }
    }
  }
}

// OVERRIDES

// Activity panel
.ysa-panel-activity {
  .panel-heading-meta {
    color: $darkblue !important;
    .panel-heading-meta-right {
      border-left-color: $darkblue !important;
    }
  }
}

.ysa-panel-award-submission {
  .panel-heading {
    .title {
      color: $ysa_black;
    }

    .panel-heading-meta {
      align-items: center;

      .panel-heading-meta-right {
        .award {
          &.bronze {
            color: $ysa-bronze;
          }

          &.silver {
            color: $ysa_silver;
          }

          &.gold {
            color: $ysa_gold;
          }
        }
      }

      .panel-heading-meta-left {
        .year {
          color: $ysa_black;
        }
      }
    }
  }
}

.ysa-panel-group,
.ysa-panel-organisation {
  .participant-count {
    .fa-user {
      margin-right: 10px;
      color: $ysa-grey;
    }
  }
}

.ysa-panel-opportunity {
  &.featured-to-your-groups {
    border: solid 2px $featured;

    .featured-to-your-groups-text {
      color: $grey3;
      font-size: 14px;
    }

    .panel-heading {
      background-color: $featured;
    }
  }

  // these have specific heading colours set by the 'colour' field on partners table and displayed with inline styles
  &.ysa-panel-partner-opportunity {
    .panel-heading {
      align-items: center;
      color: white !important;
      a {
        color: white !important;
      }
    }

    .partner-opportunity-info {
      font-size: 12px;
    }
    .partner-logo-small {
      width: 25px;
      margin-left: 5px;
    }

    &.featured-to-your-groups {
      .featured-to-your-groups-text {
        color: $grey2;
      }
    }
  }
}

.ysa-panel-partner {
  .panel-heading {
    color: white !important;
    a {
      color: white !important;
    }
  }
}

.force-assess {
  color: $ysa-red;
}
