//.ui-timepicker-container{ z-index: 99999999 !important;}

.input-group-addon {
  border-width: 0;
}

.featherlight {
  // timepicker hack
  background: rgba(0, 0, 0, 0.8);

  z-index: 2000;

  table.featherlight-inner {
    display: table;
  }

  .featherlight-close-icon {
    top: 1% !important;
    right: 2% !important;
  }

  .featherlight-content {
    width: 90%;
    border-radius: 30px;
    padding: 30px 30px 0;
    border-bottom-width: 35px;

    @media screen and (min-width: $screen-sm-min) {
      width: 708px;
    }

    @media only screen and (max-width: 1024px) {
      padding: 30px 30px 0;
      border-bottom-width: 35px;
    }
    @media only screen and (max-width: 768px) {
      padding: 20px 20px 0;
      border-bottom-width: 35px;
    }
    @media only screen and (max-width: 450px) {
      padding: 15px 15px 0;
      border-bottom-width: 35px;
    }

    h2 {
      @include museo-bold;
      font-size: 25px;
      padding-bottom: 20px;
      border-bottom: 2px solid $grey2;
      margin-bottom: 35px;
    }

    h3 {
      @include museo-bold();
      padding-bottom: 20px;
      border-bottom: 2px solid $grey2;
      margin: 60px 0 20px 0;

      &:first-of-type {
        margin-top: 20px;
      }
    }
  }

  .featherlight-loading {
    .featherlight-close-icon {
      display: none;
    }
    .featherlight-content {
      background-color: transparent;
    }
  }
}
