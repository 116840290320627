.activity-status {
  @include museo-reg();

  //**** Participant-facing statuses****//

  // Draft
  @include activityStatusColor("draft", $draft);

  // Logged
  @include activityStatusColor("moderator_approved", $ysa_green);

  // Pending
  @include activityStatusColor("assessor_pending", $ysa_teal);
  @include activityStatusColor("assessor_in_progress", $ysa_teal);
  @include activityStatusColor("assessor_approved", $ysa_teal);
  @include activityStatusColor("moderator_late", $ysa_teal);
  @include activityStatusColor("resubmitted", $ysa_teal);

  // Assessor late
  @include activityStatusColor("assessor_late", $ysa_orange);

  // Rejected
  @include activityStatusColor("moderator_rejected", $ysa_red);
  @include activityStatusColor("assessor_rejected", $ysa_red);

  // Override some of the statuses for moderator's view
  &.activity-moderation-status {
    // For moderation
    @include activityStatusColor("assessor_approved", $ysa_green);
    @include activityStatusColor("resubmitted", $ysa_green);

    // Late assessment / Late moderation
    @include activityStatusColor("assessor_late", $ysa_red);
    @include activityStatusColor("moderator_late", $ysa_red);
  }
}

.activity-status-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.activity-status-text {
  display: inline-block;
}

.award-submission-moderation-status {
  // For moderation
  @include activityStatusColor("moderator_pending", $ysa_green);

  // Rejected
  @include activityStatusColor("hq_rejected", $ysa_red);
}
