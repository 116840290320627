#stats-view {
  padding-left: $ysa-padding_medium * 3/4;
  padding-right: $ysa_padding_medium * 3/4;
}

.chart-wrapper {
  padding: $ysa-padding_smallest;
  clear: both;
}

#strand-chart,
#discipline-chart {
  text {
    fill: $ysa-dark-grey;
    font-size: 16px;
  }
}

#activities-info {
  margin-bottom: $ysa_padding_large;
}
