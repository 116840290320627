h1,
h2,
.h1,
.h2 {
  @include museo-bold;
}

h3,
h4,
h5,
h6 {
  @include museo-reg;
}

.Bronze-text,
.bronze-text {
  color: $ysa_bronze !important;
}

.Silver-text,
.silver-text {
  color: $ysa_silver !important;
}

.Gold-text,
.gold-text {
  color: $ysa_gold !important;
}

.teal {
  color: $ysa_teal;
}
