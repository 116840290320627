@mixin fill($colour) {
  background-color: $colour;
}

@mixin museo-bold {
  font-family: MuseoSansRounded-900;
  font-weight: normal;
  font-style: normal;
}

@mixin museo-reg {
  font-family: MuseoSansRounded-700;
  font-weight: normal;
  font-style: normal;
}

@mixin arrow_hover($color) {
  &:hover {
    .link_arrow {
      .fill {
        fill: $color;
      }
    }
  }

  &.hovered {
    .link_arrow {
      .fill {
        fill: $color;
      }
    }
  }
}

@mixin arrow_stroke($color) {
  .link_arrow {
    .stroke {
      fill: $color;
    }
  }
}

@mixin activeMenuLink($color) {
  &.active {
    a {
      color: $color;
    }
  }
}

@mixin hoverMenuLink($color) {
  a {
    &:hover {
      color: $color;
    }
  }
}

@mixin globalPageColor($color) {
  #footer {
    a {
      color: $color;
    }
  }
  .showcase-slider {
    .strip {
      a {
        color: $color;
      }
    }
  }

  .main-card {
    h1 {
      color: $color;
    }
  }
}

@mixin leftRightPad() {
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $screen-xs-min) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@mixin topBottomPad() {
  padding-top: 15px;
  padding-bottom: 15px;

  @media (min-width: $screen-xs-min) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@mixin fullWidthInCard() {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $screen-xs-min) {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@mixin cardPadding($bothSides) {
  $pad_full: 60px;

  padding-left: $pad_full/4;
  @if $bothSides == true {
    padding-right: $pad_full/4;
  }

  @media screen and (min-width: $screen-xs-min) {
    padding-left: $pad_full/2;
    @if $bothSides == true {
      padding-right: $pad_full/2;
    }
  }
}

@mixin bxSliderControls($color) {
  .bx-controls-direction {
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
    .bx-prev {
      left: -40px;
      background: url("img/Arrow-#{$color}-left.svg") no-repeat;
      &:hover {
        background: url("img/Arrow-#{$color}-left-fill.svg") no-repeat;
      }
    }
    .bx-next {
      right: -40px;
      background: url("img/Arrow-#{$color}-right.svg") no-repeat;

      &:hover {
        background: url("img/Arrow-#{$color}-right-fill.svg") no-repeat;
      }
    }
  }
}
@mixin strandPageColor($color) {
  .requirements-card {
    background-color: $color;

    .requirements-tabs {
      .tab {
        background-color: lighten($color, 15%);
      }
    }
  }
}

// WEBAPP
@mixin activityStatusColor($status, $color) {
  &.#{$status} {
    color: $color;

    .activity-status-icon {
      fill: $color;
    }
  }
}
