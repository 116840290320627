.user-finder {
  input[type="text"] {
    margin-bottom: 40px;
  }

  .helper-text {
    color: $grey;
  }

  a {
    color: $ysa_black;

    &:hover {
      text-decoration: underline;
    }
  }

  .main {
    border-bottom: 1px solid $ysa-grey;
  }

  .filters {
    border-left: 1px solid $ysa-grey;

    h3 {
      color: $ysa-teal;
      border: 2px solid $ysa-light_grey;
      border-radius: 8px;
      padding: 10px 12px 7px;
    }

    .no-results {
      margin: 1em 0;
      font-weight: bold;
    }
  }

  .footer {
    h4 {
      i {
        color: $ysa-teal;
        margin-right: 10px;
      }
    }
  }
}
