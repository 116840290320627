.stemm-progress {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around;

  li {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 479px) and (max-width: 670px) {
      width: 31%;
    }
  }

  .stemm-progress-text {
    font-size: 15px;
    @include museo-reg();
  }

  .stemm-progress-icon {
    width: 40px;
    height: 40px;
  }
}

.dash-progress-stemm {
  margin-top: 10px;
}
