.opportunity {
  section {
    margin-bottom: 40px;
    padding-bottom: 30px;
    border-bottom: 2px solid $grey2;
    h3 {
      margin-top: 0 !important;
      border-bottom: none !important;
      padding-bottom: 0 !important;
    }
  }

  .opportunity-target-audience-overview {
    margin-bottom: 20px;
  }
}

.opportunity,
#activity-single-view {
  .partner-opportunity-wrapper {
    display: flex;
    align-items: center;
    margin-top: -26px;
    margin-bottom: 20px;
  }
  .partner-opportunity-blurb {
    margin-top: 5px;
    margin-bottom: 5px;
    flex: 1 1 auto;
  }

  .partner-logo {
    width: 64px;
  }
}
