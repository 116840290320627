table {
  th,
  td {
    &.shrink {
      white-space: nowrap;
    }
    &.expand {
      width: 99%;
    }
  }

  &.pill-table {
    border-collapse: separate;
    border-spacing: 0 10px;
    font-family: $headings-font-family;
    color: $ysa-dark-grey;

    &.rows-are-links {
      color: $ysa-blue;
      a {
        color: $ysa-blue;
      }

      tr {
        //&:hover {
        //  cursor: pointer;
        //
        //  td {
        //    background-color: darken($grey2, 5%);
        //  }
        //}
      }
    }

    td {
      &.participant-count:before {
        @extend .fa;
        @extend .fa-user;
        margin-right: 10px;
        color: $ysa-grey;
      }
    }

    a {
      color: $ysa-dark-grey;
    }

    tr {
      background-color: transparent;

      > th {
        border: none;
        padding-bottom: 0;
      }

      > td {
        padding: 6px ($grid-gutter-width / 2);
        background-color: $grey2;
        border-color: transparent;
        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}
