#update-credits-table {
  @include museo-reg;

  thead {
    @include museo-bold;
  }
}

#credits-counter {
  @include museo-reg;

  .assigned {
    color: $ysa-grey;
  }
  margin-bottom: $ysa-padding_medium;
}

.credits-well {
  @include museo-reg;

  span {
    display: block;
  }
}
