.dash {
  &.panel-group {
    margin-bottom: 0;
  }
  .btn-expand-collapse-container {
    .btn-expand-collapse {
      margin-top: 20px; // mimic h2
    }
  }

  .dash-partner {
    // background colour is based on partner's colour so all text is white
    color: white;
    h2,
    p {
      color: white;
    }
  }

  #dash-strands-heading {
    .btn-expand-collapse-container {
      .btn-expand-collapse {
        margin-top: 10px; // mimic h2
      }
    }
  }
  .panel {
    // the h2 expand and collapse panels - they are clickable
    h2 {
      cursor: pointer;
    }
  }

  .card {
    .panel-collapse {
      .collapse-padding {
        padding-top: 35px;
      }
      h3:first-child {
        margin-top: 0;
      }
    }
  }

  .panel {
    margin-bottom: 0;
  }

  // Dash header
  .dash-header {
    @include leftRightPad();
  }

  .dash-header-sub {
    color: $white;
    margin: 0;
  }

  .dash-header-sub-highlighted {
    color: $ysa_teal;
    margin: 5px 0;
  }

  .panel {
    //background-color: transparent;
    border: none;
  }
  // generic
  .panel-body {
    padding: 10px 15px;
  }

  h2,
  h3 {
    &.bold {
      @include museo-bold();
    }
    &.black {
      color: $ysa_black;
    }
    color: $ysa_blue;
  }

  .dash-groups h3 {
    margin-top: 60px;
  }

  .card-subtitle {
    @include leftRightPad();

    h2 {
      display: block;
      color: $white;
      padding-top: $ysa_padding_smallest + 4;
      padding-bottom: $ysa_padding_smallest;
      margin: 0;
    }
  }

  .progress {
    margin-bottom: 5px;

    .progress-bar {
      text-align: right;

      span {
        padding-right: 6px;
        color: $white;
      }
    }
  }

  .dash-star,
  .dash-medal {
    max-width: 100%;
    min-width: 40px;
  }

  // award
  .dash-award {
    @include leftRightPad();
  }

  .dash-progress-text {
    font-weight: 700;
    @include museo-reg();
  }

  // strands
  .dash-strands {
    padding-bottom: 30px;

    //hack - works with app.js expand/collapse text function
    background-color: white;

    &.shaping-expanded {
      background-color: $purple_light;
    }
  }

  .dash-strand {
    @include leftRightPad;
    @include topBottomPad;
    border-radius: 0;
    &:not(:last-child) {
      border-bottom: solid 2px $grey;
    }
    margin-top: 0 !important;
    transition: background-color 0.5s;
    &.expanded {
      background-color: white;
    }
  }

  //Activities
  .dash-activities {
    .dash-activities-approved,
    .dash-activities-unapproved {
      margin-bottom: 50px;
    }
  }

  // colours for the award level

  &.dash-bronze {
    .dash-award-name {
      span {
        color: $ysa_bronze;
      }
    }

    .dash-strands {
      //background-color: $bronze;
    }
    .card-subtitle {
      background: $bronze;
    }

    .progress-bar {
      background-color: $bronze;
    }
  }

  &.dash-silver {
    .dash-award-name {
      span {
        color: $ysa_silver;
      }
    }

    .card-subtitle {
      background: $silver;

      .btn-expand-collapse {
        color: rgba($ysa_white, 0.8);
      }

      .progress-bar {
        background-color: $bronze;
      }
    }
  }

  &.dash-gold {
    .dash-award-name {
      span {
        color: $ysa_gold;
      }
    }
    .card-subtitle {
      background: $gold;

      //i don't think that's used
      .progress-bar {
        background-color: $bronze;
      }
      .btn-expand-collapse {
        color: $white;
      }
    }
  }

  // Colours for each strand
  .dash-strand-Inspiring {
    background: $red_light;

    .progress-bar {
      background-color: $red;
    }

    span {
      color: $red;
    }
  }

  .dash-strand-Engaging {
    background: $orange_light;

    .progress-bar {
      background-color: $orange;
    }

    span {
      color: $orange;
    }
  }

  .dash-strand-Developing {
    background: $green_light;

    .progress-bar {
      background-color: $green;
    }

    span {
      color: $green;
    }
  }

  .dash-strand-Shaping {
    background: $purple_light;

    .progress-bar {
      background-color: $purple;
    }

    span {
      color: $purple;
    }
  }

  //.dash-strand.expanded {
  //  background: $white;
  //}

  // checkboxes
  .checkbox-wrap {
    display: block;
    padding: 5px 10px;
    font-weight: 700;
    background: $grey2;
    border-radius: 10px;
    margin-bottom: 10px;

    label {
      @include museo-reg();
      padding-left: 5px;
    }
  }

  .btn-expand-collapse {
    color: $grey;
    padding-right: 0;
  }

  .panel-collapse {
    //padding: 15px 0;
  }

  // quick approve on hq
  .btn-quick-approve,
  .quick-approve-text {
    font-size: 12px;
    border-left: 2px solid #e7e7e7 !important;
    border-radius: 0;
    padding-left: 10px;
    margin-left: 10px;
  }

  .quick-approve-text {
    height: 25px;
    min-height: 25px;
    transition: all 0.3s ease-in;
    min-width: 0;
    width: 120px;

    &:focus {
      min-height: 100px;
      min-width: 100%;
    }
  }

  #dash-messages-content {
    //padding-top: 40px;
  }

  .dash-deadlines {
    .deadline {
      color: $ysa-teal;
    }
  }

  .dash-deadlines,
  .dash-change-award-notice {
    p {
      margin-bottom: 0px;
    }
    margin-bottom: 20px;
  }

  .award-submission {
    margin-top: 40px;
    h3 {
      margin-top: 0;
    }
    hr.award-submission-line {
      padding-bottom: 20px;
      @include fullWidthInCard();
      border-top-width: 5px;

      &.Bronze {
        border-color: $ysa_bronze;
      }

      &.Silver {
        border-color: $ysa_silver;
      }

      &.Gold {
        border-color: $ysa_gold;
      }
    }
  }

  .incomplete-profile-reminder {
    h3 {
      color: white;
      margin-top: 0;
    }
    p {
      color: white;
      margin-bottom: 0;
    }

    background-color: $orange;
    padding: 20px;
    border-radius: 20px;
  }
}

.dash-partner-logo {
  @media screen and (max-width: $screen-xs-max-new) {
    margin-top: $ysa_padding_small;
  }
}

.dashboard-header-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  margin-top: 22px;
  justify-content: space-between;
  align-items: center;
  h1 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.user-guide {
  @extend .museo-reg;
  font-size: 20px;
}
