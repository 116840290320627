#main-nav {
  @include museo-reg();
  .container {
    @include leftRightPad();
    padding-top: 10px;
    padding-bottom: 10px;
  }

  a {
    color: $grey3;

    &:hover,
    &:active {
      color: $grey2;
    }
  }

  .label-grey {
    background: $grey3;
    border-radius: 20px;
    color: $black;
  }

  .label-cyan {
    background: $ysa_teal;
    border-radius: 20px;
    color: $white;
  }

  ul {
    font-size: 0;
  }

  li {
    font-size: 16px;
    border-left: 1px solid $grey3;
    padding-left: 10px;
    padding-right: 10px;

    &:first-child {
      border: none;
    }
  }
}
