.strand-icon {
  &.small {
    width: 25px;
    height: 25px;
  }
}

.strand-name {
  color: $ysa_blue;
}
span.strand-slug {
  &.inspiring {
    color: $ysa_inspiring_red;
  }

  &.engaging {
    color: $ysa_engaging_orange;
  }

  &.developing {
    color: $ysa_developing_green;
  }

  &.shaping {
    color: $ysa_shaping_purple;
  }
}
