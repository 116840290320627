.activity-evidence {
  color: $darkblue;
  font-weight: 700;
}

.activity-evidence-icon {
  width: 20px;
  height: 20px;
  fill: $darkblue;
}

.file-li {
  display: flex;
  justify-content: space-between;
}
