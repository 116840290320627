.btn-red,
.btn-Inspiring {
  @include button-variant($white, $red, $red);
}

.btn-orange,
.btn-Engaging {
  @include button-variant($white, $orange, $orange);
}

.btn-green,
.btn-Developing {
  @include button-variant($white, $green, $green);
}

.btn-purple,
.btn-Shaping {
  @include button-variant($white, $purple, $purple);
}

.btn-cyan {
  @include button-variant($white, $ysa_teal, $ysa_teal);
}

.btn-Bronze,
.btn-bronze {
  @include button-variant($white, $ysa_bronze, $ysa_bronze);
}

.btn-Silver,
.btn-silver {
  @include button-variant($white, $ysa_silver, $ysa_silver);
}

.btn-Gold,
.btn-gold {
  @include button-variant($white, $ysa_gold, $ysa_gold);
}

.btn-blue {
}

.btn-white-outline {
  @include button-variant($white, transparent, $white);
  border-width: 2px;
}

.btn-grey {
  @include button-variant($white, $ysa_grey, $ysa_grey);
}

.btn-link-cyan {
  color: $ysa_teal;
}

.btn-enable-form-edit {
  @include button-variant($white, $ysa_blue, $ysa_blue);
  font-size: 12px;
  padding: 5px 10px;
}

.btn-filters {
  padding-top: 5px;
  padding-bottom: 3px;
  background-color: white;
  border: solid 2px $grey2;
  color: $ysa-dark-grey;
  margin-bottom: 10px;
  transition: all 0.1s;

  &:hover {
    color: $grey;
  }

  &:focus {
    outline: none;
  }
}

.buttons-right {
  display: flex !important; // bloody featherlight-inner overriding my styles
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: -5px;

  .btn {
    margin: 5px;
  }
}

.buttons-space-between {
  display: flex !important; // bloody featherlight-inner overriding my styles
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -5px;

  .btn {
    margin: 5px;
  }
}

.award-submission-btns {
  .fa-star {
    transform: rotate(-10deg);
    margin-right: 5px;
  }
}

.btn-award-change {
  font-size: 12px;
  padding: 6px;
}

.btn-return {
  i {
    margin-right: 5px;
  }
}

.btn-full-width {
  display: block;
  width: 100%;
}
