.assessment-decision-buttons > .btn {
  &.btn-danger {
    font-size: 16px;
    padding: 6px;

    &:before {
      @extend .fa;
      @extend .fa-times;
      float: right;
      margin-left: 5px;
    }
  }

  &.btn-success {
    &:before {
      @extend .fa;
      @extend .fa-check;
      float: right;
      margin-left: 8px;
    }
  }
}
