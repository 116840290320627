.datepicker {
  width: 100%;

  table {
    width: 100%;

    tr {
      td,
      th {
        height: 30px;
      }
    }
  }
}

.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  background: $ysa_blue;
  border-color: $ysa_blue;
}
