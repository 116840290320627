#notifications {
  &:hover {
    cursor: pointer;
  }

  .badge {
    margin-bottom: 2px;
  }
  .notifications-dropdown {
    .messages-notification-tray {
      padding: 0;

      &:hover {
        background-color: transparent;
        color: $ysa_black;
      }
    }

    .info-text {
      margin-top: 6px;
      margin-bottom: 0px;
    }
  }
}

.badge-cyan {
  background-color: $ysa-teal;
}
