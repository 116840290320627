body.login,
body.reset {
  #content {
    background-color: transparent;
    padding: 50px 0;
    box-shadow: none;

    .card.black {
      border-radius: 30px 30px 0 0;
    }

    .card.white {
      border-radius: 30px !important;
    }

    img#login-stars {
      position: absolute;
      top: 80px;
      right: 40px;
      width: 150px;
    }
  }
}

.register-your-interest {
  margin-top: $ysa_padding_medium;
}
