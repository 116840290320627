.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: $ysa_padding_smallest;
}

.mb-2 {
  margin-bottom: $ysa_padding_small;
}
