.discipline-checks {
  &.with-labels {
    .discipline-icon {
      margin-bottom: 8px;
    }
    label {
      font-size: 12px;
      text-align: center;
      padding: 0 10px 0 0;
    }
  }

  &:not(.with-labels) {
    label {
      margin-bottom: 0;
    }
  }

  ul.list-inline {
    margin-bottom: 0;
    margin-left: 0;

    li {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  .discipline-icon {
    display: block;
    fill: $grey;

    &.active {
      fill: $darkblue;
    }

    &.small {
      width: 25px;
      height: 25px;
    }

    &.large {
      //width:50px;
      //height:50px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
