$fa-font-path: "../fonts";

// eportfolio colours!
$white: #fff;
$black: #000;

$red: #cf563d;
$orange: #f0902d;
$green: #76b74e;
$purple: #7567a9;

$red_light: #faefea;
$orange_light: #fff5eb;
$green_light: #f4f8ef;
$purple_light: #f0eff8;

$bronze: #cd7f31;
$silver: #bcbcbc;
$gold: #d7b135;

//$cyan: #52b889;
$featured: #ffda97;
$darkblue: #02637b;
$draft: #1e2a45;
$approved: #95c11f;
$grey: #b3b3b2;
$lightgrey: #f7f6f6;

$grey2: #e7e7e7;
$grey2light: #f7f6f6;

$ysa-mid-grey: #dcddde;
$ysa-dark-grey: #403b39;

$grey3: #888887;

//ysa?

$ysa_black: #1d1d1b;
$ysa_grey: #b3b3b2;
$ysa_light_grey: #f7f6f6;
$ysa_bg: #3d3d3c;

//$ysa_dark_grey: #2f2f2f;
//$ysa_kinda_dark_grey: #706f6f;
$ysa_white: #ffffff;
$ysa_teal: #28b78f;
$ysa_red: #d65542;
$ysa_orange: #f8903c;
$ysa_green: #96c120;
$ysa_purple: #7466a5;
$ysa_blue: #006379;
//$ysa_bg:      #F5F5F5;

$ysa_bronze: #ce7e44;
$ysa_silver: #a7abae;
$ysa_gold: #cea348;

$ysa_inspiring_red: #ce553d;
$ysa_engaging_orange: #ef8f2c;
$ysa_developing_green: #75b54d;
$ysa_shaping_purple: #7466a8;

// NEW VARIABLES

$ysa_padding_medium: 40px;
$ysa_padding_large: 60px;
$ysa_padding_small: 20px;
$ysa_padding_smallest: 10px;

$ysa_font_smaller: 15px;
$ysa_font_small: 16px;
$ysa_font_medium: 18px;
$ysa_font_medium_larger: 19px;
$ysa_font_large: 20px;
$ysa_font_larger: 22px;
$ysa_font_largest: 35px;
