.privacy-policy-popup,
.profile-incomplete-popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(black, 0.8);
  width: 100%;
  height: 100vh;
  z-index: 10000;
  display: flex;
  align-items: center;

  .popup {
    border-radius: 30px;
  }

  // js function in app.js to prevent scrolling
}

.privacy-policy-popup {
  z-index: 10010;
}

.privacy-policy-popup--wrapper,
.profile-incomplete-popup--wrapper {
  display: none;
}
