.card {
  box-shadow: 0px -7px 43px -16px rgba($ysa_black, 0.5);

  /* additional 30px of bottom padding to account for -20px margin when cards
   * are stacked
   *
   * bottom padding is reduced when it's the last card
   */
  // top right bottom left
  padding: 30px 0 60px 0;
  @include cardPadding(true);

  &:last-of-type {
    padding-bottom: $ysa_padding_medium;
  }

  // increase z-index with each card (enables stacking)
  @for $i from 1 through 10 {
    // we won't have more than 10 cards on any given page
    &:nth-child(#{$i}) {
      z-index: #{$i};
    }
  }

  &:not(:first-child) {
    // overlap cards
    margin-top: -30px;

    &.panel {
      margin-top: -30px; // we need to explicitly override panel styling here
    }

    //round top corners
    border-radius: 30px 30px 0px 0px;
  }

  // FILL COLOURS
  &.black {
    @include fill($ysa_black);
    color: $ysa_white;
  }

  &.teal {
    @include fill($ysa_teal);
    color: $ysa_white;
  }

  &.green {
    @include fill($ysa_green);
    color: $ysa_white;
  }

  &.grey {
    @include fill($ysa_grey);
    color: $ysa_black;
    box-shadow: 0px -7px 35px -9px rgba(27, 27, 26, 0.44);
  }

  &.white {
    @include fill($ysa_white);
    color: $ysa_black;
  }

  &.blue {
    @include fill($ysa_blue);
    color: $ysa_white;
  }

  &.red {
    @include fill($ysa_red);
    color: $ysa_white;
  }

  &.main-card {
    @include museo-reg;

    &.large-font {
      font-size: $ysa-font_medium;

      @media screen and (min-width: $screen-sm-min) {
        font-size: $ysa_font_large;
      }
      @media screen and (min-width: $screen-md-min) {
        font-size: $ysa_font_larger;
      }
    }

    h1 {
      margin-top: 0px;
    }

    img {
      padding-top: 20px;

      @media screen and (min-width: $screen-sm-min) {
        padding-top: 0;
      }
    }
  }
}
