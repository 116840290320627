.evidence {
  li {
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    background: $grey2light;

    .fa {
      padding: 0;
    }
  }

  h4 {
    span {
      color: $gray;
    }
  }

  .delete-file,
  .delete-link {
    color: $red;
  }

  .save-file {
    color: $ysa_blue;
  }

  .disabled & {
    .edit-visible-only {
      display: none;
    }

    input[type="url"] {
      user-select: none;
      cursor: pointer;
      color: $link-color;
    }
  }

  .evidence-links {
    .row {
      margin-bottom: 5px;
    }
  }

  .evidence-link-title,
  .evidence-link-url {
    @extend .col-xs-5;

    .disabled & {
      @extend .col-xs-6;
    }
  }
}

#viewActivityForm {
  .evidence {
    margin-bottom: $ysa_padding_large;
  }
  .add-link {
    margin-bottom: $ysa_padding_small;
  }
}
