body {
  min-width: 320px;
}

.container {
  padding: 0;
}

hr {
  border-color: #ccc;
}

.display-flex {
  &.flex-vertical-center {
    align-items: center;
  }
  display: flex;

  .flex-vertical-center {
    align-self: center;
  }
}

ul.grey-block {
  li {
    background-color: $input-bg;
    //display: block;
    border-radius: 10px;
    padding: 6px 15px;
    margin-bottom: 5px;
  }
}
